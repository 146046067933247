import { ReactElement, useEffect, useImperativeHandle, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { AlertBox, Typography } from '@vartanainc/design-system';
import { isEmpty, some } from 'lodash';
import * as yup from 'yup';

import { fetchJobTitles, reportError } from '../../../utils/helpers';
import PatternInput from '../../../designSystem/PatternInput/PatternInput';
import TextInputField from '../../../designSystem/TextInput/TextInputField';
import FreeTextDropdownInput from '../../../designSystem/FreeTextDropdownInput/FreeTextDropdownInput';
import { commonRegex, FIELD_INVALID_MESSAGES } from '../../../constants/common.constants';
import SvgIcon from '../../../components/SvgIcon/SvgIcon';

const formSchema = yup.object().shape({
  firstName: yup
    .string()
    .nullable()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Legal first name is required')
    .matches(commonRegex.alphabetsAndSingleSpace, FIELD_INVALID_MESSAGES.firstName),
  lastName: yup
    .string()
    .nullable()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Last name is required')
    .matches(commonRegex.alphabetsAndSingleSpace, FIELD_INVALID_MESSAGES.lastName),
  email: yup
    .string()
    .nullable()
    .typeError('Invalid email')
    .email('Invalid email')
    .required('Email is required'),
  phone: yup
    .string()
    .required('Phone number is required')
    .test('len', 'Phone number must be 10 digits', (val) => {
      return !val?.includes('_');
    }),
  jobTitle: yup
    .string()
    .nullable()
    .required('Job title is required')
    .test('validity check', 'Please enter a valid job title', (value) => {
      if (value) return commonRegex.alphabetsAndSpaces.test(value);
      return false;
    }),
});

interface AuthSignerFormProps {
  handleSubmit: () => void;
  setSubmissionDisabled: (isDisabled: boolean) => void;
  formRef: React.RefObject<unknown>;
  authSigner?: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    title: string;
    defaultAddress: {
      street: string;
      city: string;
      state: string;
      zip: string;
    };
  };
  alertText?: string;
}

export function AuthSignerForm({
  handleSubmit,
  setSubmissionDisabled,
  formRef,
  authSigner,
  alertText,
}: AuthSignerFormProps): ReactElement {
  const formikBag = useFormik({
    initialValues: {
      firstName: authSigner?.firstName,
      lastName: authSigner?.lastName,
      email: authSigner?.email,
      phone: authSigner?.phone?.replace('+1', ''),
      jobTitle: authSigner?.title,
    },
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: handleSubmit,
  });

  const { setFieldValue } = formikBag;

  useImperativeHandle(formRef, () => formikBag);

  useEffect(() => {
    const isDisabled =
      some(formikBag.values, (value) => !value) ||
      formikBag.isSubmitting ||
      !formikBag.isValid;

    setSubmissionDisabled(isDisabled);
  }, [
    formikBag.values,
    formikBag.isSubmitting,
    formikBag.isValid,
    setSubmissionDisabled,
  ]);

  const [jobTitles, setJobTitles] = useState([]);

  useEffect(() => {
    // this useEffect helps us fetch job titles when the form component mounts

    const titles = JSON.parse(sessionStorage.getItem('job-titles') || '{}');
    if (isEmpty(titles)) {
      fetchJobTitles()
        .then((response) => {
          setJobTitles(response.titles);
          sessionStorage.setItem('job-titles', JSON.stringify(response.titles));
        })
        .catch((error) => reportError(error.message, error));
    } else {
      setJobTitles(titles);
    }
  }, []);

  return (
    <FormikProvider value={formikBag}>
      <Typography variant="heading18" bold>
        Authorized signer
      </Typography>
      {alertText && (
        <div className="mt-4 max-w-[36.5rem]">
          <AlertBox
            variant="alert-box"
            backgroundColor="announcement"
            icon={<SvgIcon name="info" fill="color-blue-120" />}
            content={alertText}
          />
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <fieldset>
          <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-row gap-4">
              <TextInputField
                {...{
                  name: 'firstName',
                  label: 'Legal first name',
                  fullWidth: 'true',
                }}
                ref={null}
              />
              <TextInputField
                {...{
                  name: 'lastName',
                  label: 'Last name',
                  fullWidth: 'true',
                }}
                ref={null}
              />
            </div>
            <div
              className={`flex flex-row gap-4 ${
                authSigner?.phone ? 'filled-auth-signer-fields' : ''
              }`}
            >
              <TextInputField
                {...{
                  name: 'email',
                  label: 'Email',
                  placeholder: 'example@email.com',
                  fullWidth: 'true',
                }}
                ref={null}
              />
              <PatternInput
                id="phone"
                name="phone"
                label="Phone number"
                mask="_"
                disableFullStoryRecording
                format="+1 (###) ###-####"
                placeholder=""
                value={formikBag.values.phone}
              />
            </div>
            <FreeTextDropdownInput
              id="jobTitle"
              name="jobTitle"
              label="Job title"
              options={jobTitles}
              onSelectOption={(e) => {
                setFieldValue('jobTitle', e.value);
              }}
            />
          </div>
        </fieldset>
      </form>
    </FormikProvider>
  );
}
