/*
This component is a wrapper for the Personal Guarantor form. 
It is a card component that contains the form and buttons for submitting or skipping the form. 
The card has a title that explains what a personal guarantor is and what their role is in the company. 
The wrapper further contains a form and a footer component with buttons for submitting or skipping the form.
*/

import { ReactElement, useCallback, useMemo } from 'react';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import { uniqueId, get } from 'lodash';
import * as yup from 'yup';
import { Card, Typography } from '@vartanainc/design-system';
import { useReactiveVar } from '@apollo/client';
import { toast } from 'react-toastify';
import SvgIcon from '../../components/SvgIcon/SvgIcon';

import { sessionVar } from '../../graphql/cache';
import { ReactComponent as AddAnotherIcon } from '../../assets/circle_cross.svg';
import { PersonalGuarantorForm } from './PersonalGuarantorForm';
import { ADD_ANOTHER_GUARANTOR } from '../../constants/common.constants';
import FormCheckbox from '../../components/FormCheckboxV1';
import { PersonalGuarantorFormFooter } from './PersonalGuarantorFormFooter';
import { TEXT_CONSTANTS } from '../../constants/ui/text.constants';
import { Guarantor, GuarantorWithoutId, PgFormValues } from './PersonalGuarantorTypes';
import CustomNotification from '../../components/CustomNotification';
import { formSchema, ssnSchema, sinSchema } from '../../formikSchema/commonSchema';

const MAX_GUARANTORS = 5;
const MIN_GUARANTORS = 1;

const DEFAULT_GUARANTOR = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  title: '',
  ssn: '',
  sin: '',
  dob: '',
  street: '',
  city: '',
  zip: '',
  state: '',
  percentageOfOwnership: '',
  primary: false,
};

const INITIAL_FORM_VALUES = {
  personalGuarantors: [
    {
      ...DEFAULT_GUARANTOR,
      id: uniqueId(),
      primary: true,
    },
  ],
  pgConsent: false,
};
interface PersonalGuarantorProps {
  handleBackButtonClick: () => void;
  handleSkipButtonClick: () => Promise<void>;
  setGuarantorsInForm: (
    personalGuarantors: GuarantorWithoutId[]
  ) => Promise<{ [key: string]: string[] }>;
  showSinField: boolean;
  selectedCountry: string;
}

export default function PersonalGuarantor({
  handleBackButtonClick,
  handleSkipButtonClick,
  setGuarantorsInForm,
  showSinField,
  selectedCountry,
}: PersonalGuarantorProps): ReactElement {
  const pgFormSchema = formSchema.concat(showSinField ? sinSchema : ssnSchema);

  const showPersonalGuarantorFieldError = (errorMessage: string): void => {
    toast.error(({ toastProps }) => (
      <CustomNotification
        type={toastProps.type || 'error'}
        title="Information invalid"
        message={errorMessage}
      />
    ));
  };

  // Function to handle submission of the Personal Guarantor form
  async function submitPersonalGuarantorForm(
    values: PgFormValues,
    formikHelpers: FormikHelpers<PgFormValues>
  ): Promise<void> {
    // Process guarantor data by removing fields that should not be sent if empty
    const guarantorValues = values.personalGuarantors.map(({ id, phone, ...rest }) => {
      return phone ? { ...rest, phone } : rest;
    });

    // Submit the processed guarantor data to the parent form and retrieve any validation errors returned by the parent
    const submissionErrors = await setGuarantorsInForm(guarantorValues);

    // If there are any errors, set them in Formik's state and display an error notification for the first issue found
    if (Object.keys(submissionErrors).length) {
      formikHelpers.setErrors(submissionErrors);
      showPersonalGuarantorFieldError(
        `${Object.keys(submissionErrors)[0]} ${Object.values(submissionErrors)[0]}` // Display the first error field and its corresponding message in the error notification
      );
    }
  }

  const formikBag = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      personalGuarantors: yup
        .array()
        .of(pgFormSchema)
        .min(1, 'At least one guarantor is required'),
      pgConsent: yup.boolean().oneOf([true], 'You must accept the terms').required(),
    }),
    onSubmit: (values) => submitPersonalGuarantorForm(values, formikBag),
  });
  const sessionData = useReactiveVar(sessionVar);

  const multipleCountriesEnabled = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled',
    false
  );
  const enabledCountries = get(
    sessionData,
    'session.user.company.product.enabledCountries',
    []
  );

  const createGuarantor = (): Guarantor => ({ ...DEFAULT_GUARANTOR, id: uniqueId() });

  const addAnotherGuarantor = useCallback((): void => {
    formikBag.setFieldValue('personalGuarantors', [
      ...formikBag.values.personalGuarantors,
      createGuarantor(),
    ]);
  }, [formikBag]);

  const numberOfGuarantors = useMemo(
    () => formikBag.values.personalGuarantors.length,
    [formikBag.values.personalGuarantors]
  );

  // Memoized check to verify if all guarantor forms are valid.
  // Returns true if there are no validation errors for any guarantor; otherwise, false.
  // Recalculates only when guarantor values or errors change.
  const areAllGuarantorsValid = useMemo(() => {
    return formikBag.values.personalGuarantors.every((_, index) => {
      const errors = formikBag.errors?.personalGuarantors?.[index];
      return errors === undefined;
    });
  }, [formikBag.errors, formikBag.values.personalGuarantors]);

  const isAddGuarantorBtnDisabled =
    numberOfGuarantors >= MAX_GUARANTORS || !areAllGuarantorsValid;

  return (
    <Card
      variant="fullWidth"
      title={(
        <div className="grid gap-2.5">
          <div className="flex gap-2 items-center">
            <SvgIcon
              name="background_replace"
              width="2rem"
              height="2rem"
              fill="color-blue-120"
            />
            <Typography variant="heading20" bold color="color-black-100">
              Personal guarantor (optional)
            </Typography>
          </div>
          <Typography variant="paragraph14" color="color-black-100">
            An individual with ownership or executive responsibilities in the company who
            can guarantee the payment agreement with personal credit in case the business
            defaults.
          </Typography>
        </div>
      )}
      content={(
        <div className="pg-form-container">
          <FormikProvider value={formikBag}>
            {formikBag.values.personalGuarantors.map((guarantor, index) => (
              <PersonalGuarantorForm
                key={guarantor.id}
                guarantorIndex={index}
                showGuarantorNumber={numberOfGuarantors > MIN_GUARANTORS}
                showSinField={showSinField}
                multipleCountriesEnabled={multipleCountriesEnabled}
                enabledCountries={enabledCountries}
                selectedCountry={selectedCountry}
              />
            ))}
            <div className="flex flex-col mt-4">
              <button
                type="button"
                disabled={isAddGuarantorBtnDisabled}
                className={`${
                  isAddGuarantorBtnDisabled ? 'disabled-btn' : ''
                } flex items-center gap-1 w-fit mt-2 card-subtitle-bold text-vartana-blue-120 disabled:text-vartana-gray-40`}
                onClick={addAnotherGuarantor}
              >
                <AddAnotherIcon className="w-8 h-8" />
                <Typography
                  variant="heading16"
                  bold
                  color={isAddGuarantorBtnDisabled ? 'color-gray-100' : 'color-blue-120'}
                >
                  {ADD_ANOTHER_GUARANTOR}
                </Typography>
              </button>
            </div>
            <hr className="h-px bg-vartana-gray-40-v3 my-6" />

            <div className="flex flex-row gap-2 mt-4">
              <FormCheckbox name="pgConsent" />
              <Typography variant="paragraph14" color="color-gray-140">
                {TEXT_CONSTANTS.PG_ACKNOWLEDGEMENT}
              </Typography>
            </div>
            <PersonalGuarantorFormFooter
              handleBackButtonClick={handleBackButtonClick}
              handleSkipButtonClick={handleSkipButtonClick}
            />
          </FormikProvider>
        </div>
      )}
    />
  );
}
