import { Navigate, Route } from 'react-router-dom';
import { get } from 'lodash';
import { Customers } from '../pages/Customers';
import { CustomerEdit } from '../pages/Customers/edit';
import { OrderEdit } from '../pages/Orders/EditOrder';
import { CustomerShow } from '../pages/Customers/show';
import { RequestHigherOfferLimitsV2 } from '../pages/Customers/RequestHigherOfferLimitsV2/RequestHigherOfferLimitsV2';
import MoreInfoCustomers from '../pages/Customers/MoreInfo/MoreInfoPage';
import MoreInfoCrm from '../pages/CRM/Forms/MoreInfo/MoreInfoPage';
import { Orders } from '../pages/Orders';
import { OrderCreate } from '../pages/Orders/CreateOrder';
import CustomerSummary from '../pages/Customers/Summary';
import CustomerOrders from '../pages/Customers/Tables/CustomerOrders';
import CustomerContacts from '../pages/Customers/ContactRouter/CustomerContacts';
import ContactEdit from '../pages/Customers/ContactRouter/ContactEdit';
import ContactShow from '../pages/Customers/ContactRouter/ContactShow';
import CustomerAccountStataments from '../pages/Customers/Tables/CustomerAccountStatments';
import CustomerPaymentScheduleItems from '../pages/Customers/Tables/CustomerPaymentScheduleItems';
import TrackStatus from '../pages/Customers/TrackStatus';
import OrderShow from '../pages/Orders/show';
import Settings from '../pages/Settings';
import TalkToUs from '../pages/TalkToUs/TalkToUs';
import Profile from '../pages/Settings/Profile';
import IntegrationSettings from '../pages/Settings/IntegrationSettings';
import Users from '../pages/User';
import UpsertUser from '../pages/User/UpsertUser';
import ViewUser from '../pages/User/ViewUser';
import { Roles } from '../pages/Settings/Roles';
import AllRoles from '../pages/Settings/Roles/AllRoles';
import RoleDetail from '../pages/Settings/Roles/RoleDetail';
import Loader from '../components/Loader';
import Forms from '../pages/CRM/Forms';
import Order from '../pages/CRM/Forms/Order/CrmOrder';
import Customer from '../pages/CRM/Forms/Customer';
import Summary from '../pages/CRM/Forms/Summary';
import Application from '../pages/CRM/Forms/Application';
import ShareLink from '../pages/CRM/Forms/ShareLink/ShareLink';
import MoreDetail from '../pages/CRM/Forms/MoreDetail';
import RequestHigherLimitV2 from '../pages/CRM/Forms/RequestHigherLimitV2/RequestHigherLimitV2';
import Proposal from '../pages/CRM/Forms/Proposal';
import UploadDocuments from '../pages/CRM/Forms/UploadDocument';
import CRM from '../pages/CRM';
import WidgetV2 from '../pages/CRM/WidgetV2/widgetV2';
import ConfirmationScreen from '../pages/CRM/Forms/Confirmation';
import Token from '../api/token/Token';
import OrderCheckout from '../pages/Orders/checkout';
import { ExtendedAppDialog } from '../pages/CRM/Forms/ExtendedAppDialog';
import { OrderTabs } from '../pages/Orders/OrderTabs/OrderTabs';
import {
  ResellerInvite,
  ResellerOrders,
  ResellerProfile,
  Resellers,
} from '../pages/Resellers';
import { Vendors } from '../pages/Vendors';
import { Reseller } from '../pages/Resellers/Reseller';
import { InviteResellerForm } from '../pages/CRM/Forms/InviteResellerForm';
import { SELLER_TYPE } from '../constants/common.constants';

const renderElement = (hasPermission, Element, loading) => {
  if (hasPermission) return Element;
  if (loading) return <Loader containerClassName="absolute left-1/2 top-1/2" />;
  return <Navigate to="/access-denied" replace />;
};

/*
  Return routes based on user permissions
 */
export function getAllowedRoutesForDashboard(hasPermission, loading, sessionData) {
  const loggedInCompany = get(sessionData, 'session.user.company', {});
  const isResellerUser = loggedInCompany.canActAsReseller;

  // Resellers
  const canUseResellerWorkflow = get(loggedInCompany, 'useResellerWorkflow');
  const canInviteReseller = canUseResellerWorkflow && hasPermission('reseller', 'invite');
  const showResellerOrderDetails = !(
    canUseResellerWorkflow && loggedInCompany.hideResellerOrderDetails
  );
  const canViewExtraCustomerTabs = isResellerUser || showResellerOrderDetails;

  // Customers
  const canCreateCustomer = hasPermission('customer', 'create');
  const canEditCustomer = hasPermission('customer', 'update');
  const canViewCustomer = hasPermission('customer', 'view');
  const canRequestHigherLimit = hasPermission('order', 'request_term_modification');

  // Orders
  const canEditOrder = hasPermission('order', 'update');
  const canViewOrder = hasPermission('order', 'view');

  //  Settings
  const integrationsAllowed = hasPermission('companySetting', 'integration');

  const canViewAllRoles = hasPermission('role', 'view_all');
  const canViewRole = hasPermission('role', 'view');

  const canViewAllUsers = hasPermission('user', 'view_all');
  const canViewUser = hasPermission('user', 'view');
  const canCreateUser = hasPermission('user', 'create');

  // Vendors
  const isPureVendor = loggedInCompany.sellerType === SELLER_TYPE.VENDOR;
  const isHybrid = loggedInCompany.sellerType === SELLER_TYPE.VENDOR_RESELLER;
  const canActAsVendor = isPureVendor || isHybrid;

  return (
    <>
      {/* Vendor */}
      <Route
        path="vendors"
        element={renderElement(isResellerUser, <Vendors />, loading)}
      />

      {/* Resellers */}
      <Route
        path="resellers"
        element={renderElement(canUseResellerWorkflow, <Resellers />, loading)}
      />
      <Route
        path="resellers/invite"
        element={renderElement(canInviteReseller, <ResellerInvite />, loading)}
      />
      <Route
        path="resellers/:number"
        element={renderElement(canUseResellerWorkflow, <Reseller />, loading)}
      >
        <Route index element={<Navigate to="profile" replace />} />
        <Route path="profile" element={<ResellerProfile />} />
        <Route path="orders" element={<ResellerOrders />} />
      </Route>
      {/* Customers */}
      <Route
        path="customers/new"
        element={renderElement(canCreateCustomer, <CustomerEdit />, loading)}
      />
      <Route
        path="customers"
        element={renderElement(canViewCustomer, <Customers />, loading)}
      />
      <Route
        path="customers/:companyNumber/request-higher-limit"
        element={renderElement(
          canRequestHigherLimit,
          <RequestHigherOfferLimitsV2 />,
          loading
        )}
      />
      <Route
        path="customers/:companyNumber/request-higher-limit/v2"
        element={renderElement(
          canRequestHigherLimit,
          <RequestHigherOfferLimitsV2 />,
          loading
        )}
      />
      <Route
        path="customers/:companyNumber/more-info/:stepType"
        element={renderElement(canRequestHigherLimit, <MoreInfoCustomers />, loading)}
      />
      <Route
        path="customers/:companyNumber"
        element={renderElement(canViewCustomer, <CustomerShow />, loading)}
      >
        <Route
          path="edit"
          element={renderElement(canEditCustomer, <CustomerEdit />, loading)}
        />
        <Route index element={<Navigate to="summary" replace />} />
        <Route path="summary" element={<CustomerSummary />} />
        <Route path="orders" element={<CustomerOrders />} />
        <Route path="contacts">
          <Route
            index
            element={renderElement(canActAsVendor, <CustomerContacts />, loading)}
          />
          <Route
            path="new"
            element={renderElement(canEditCustomer, <ContactEdit />, loading)}
          />
          <Route
            path=":contactNumber/edit"
            element={renderElement(canEditCustomer, <ContactEdit />, loading)}
          />
          <Route
            path=":contactNumber"
            element={renderElement(canActAsVendor, <ContactShow />, loading)}
          />
        </Route>
        <Route
          path="payment-schedule"
          element={renderElement(
            canViewExtraCustomerTabs,
            <CustomerPaymentScheduleItems />,
            loading
          )}
        />
        <Route
          path="invoices"
          element={renderElement(
            canViewExtraCustomerTabs,
            <CustomerAccountStataments />,
            loading
          )}
        />
        <Route
          path="track"
          element={renderElement(canActAsVendor, <TrackStatus />, loading)}
        />
      </Route>

      {/* Orders */}
      <Route path="orders" element={renderElement(canViewOrder, <Orders />, loading)} />
      <Route
        path="orders/:orderNumber"
        element={renderElement(canViewOrder, <OrderShow />, loading)}
      >
        <Route index element={<Navigate to="summary" replace />} />
        <Route path="summary" element={<OrderTabs tab="summary" />} />
        <Route path="track" element={<OrderTabs tab="track" />} />
      </Route>
      <Route path="orders/new" element={<OrderCreate />} />
      <Route
        path="orders/edit"
        element={renderElement(canEditOrder, <OrderEdit />, loading)}
      />
      <Route path="orders/checkout" element={<OrderCheckout />} />

      {/* Settings */}
      <Route path="settings" element={<Settings />}>
        <Route index element={<Navigate to="profile" replace />} />
        <Route path="profile" element={<Profile />} />
        <Route
          path="integrations"
          element={renderElement(integrationsAllowed, <IntegrationSettings />, loading)}
        />
        <Route
          path="users"
          element={renderElement(canViewAllUsers, <Users />, loading)}
        />
        <Route
          path="users/create"
          element={renderElement(canCreateUser, <UpsertUser />, loading)}
        />
        <Route
          path="users/view"
          element={renderElement(canViewUser, <ViewUser />, loading)}
        />

        <Route
          path="roles"
          element={renderElement(canViewRole && canViewAllRoles, <Roles />, loading)}
        >
          <Route index element={<AllRoles />} />
          <Route path=":id" element={<RoleDetail />} />
        </Route>
      </Route>
    </>
  );
}

export function getAllowedRoutesForWidget(hasPermission, seller, loading) {
  // Customers
  const canCreateCustomer = hasPermission('customer', 'create');
  const canRequestHigherLimit = hasPermission('order', 'request_term_modification');

  // Orders
  const canEditOrder = hasPermission('order', 'update');
  const canViewOrder = hasPermission('order', 'view');
  const canUploadDocs = hasPermission('order', 'upload_documents');
  const canSendApplication = hasPermission('order', 'forward_application');

  return (
    <>
      <Route path="/forms" element={<Forms seller={seller} isLoading={loading} />}>
        <Route
          path="order/edit"
          element={renderElement(canEditOrder, <OrderEdit />, loading)}
        />
        <Route path="order" element={<Order />} />
        <Route
          path="customer"
          element={renderElement(canCreateCustomer, <Customer />, loading)}
        />
        <Route
          path="summary"
          element={renderElement(canViewOrder, <Summary />, loading)}
        />
        <Route
          path="resellers/invite"
          element={renderElement(
            hasPermission('reseller', 'invite'),
            <InviteResellerForm />,
            loading
          )}
        />
        <Route
          path="request-higher-limit"
          element={renderElement(
            canRequestHigherLimit,
            <RequestHigherLimitV2 />,
            loading
          )}
        />
        <Route
          path="request-higher-limit/v2"
          element={renderElement(
            canRequestHigherLimit,
            <RequestHigherLimitV2 />,
            loading
          )}
        />
        <Route path="extended-app-dialog" element={<ExtendedAppDialog />} />
        <Route
          path="upload-documents"
          element={renderElement(canUploadDocs, <UploadDocuments />, loading)}
        />
        <Route
          path="more-info/:stepType"
          element={renderElement(canRequestHigherLimit, <MoreInfoCrm />, loading)}
        />
        <Route
          path="application"
          element={renderElement(canSendApplication, <Application />, loading)}
        />
        <Route
          path="share-link"
          element={renderElement(canSendApplication, <ShareLink />, loading)}
        />
        <Route path="help" element={<TalkToUs />} />
        <Route path="more-details" element={<MoreDetail />} />
        <Route path="proposal" element={<Proposal />} /> {/* TODO: Deprecated route */}
        <Route path="confirmation/v2" element={<ConfirmationScreen />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>

      <Route path="/crm" element={<CRM isLoading={loading} />}>
        <Route path="widget" element={<WidgetV2 />} />
        <Route path="connect" element={<Token />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </>
  );
}
