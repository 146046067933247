import * as Yup from 'yup';
import {
  commonRegex,
  FIELD_INVALID_MESSAGES,
  SSN_SIN_FIELD_CONFIG,
  zipSchema,
  MINIMUM_GUARANTOR_AGE,
} from '../constants/common.constants';

export const passwordObj = {
  password: '',
  confirmPassword: '',
};

export const PasswordSchema = Yup.string()
  .required('This field is required')
  .min(8, 'Password should be at least 8 characters long')
  .matches(
    commonRegex.atLeastOneUpperAndLowerCaseChar,
    'Password should contain at least one uppercase and lowercase character'
  )
  .matches(commonRegex.atLeastOneNumber, 'Password should contain at least one number')
  .matches(
    commonRegex.atLeastOneSpecialChar,
    'Password should contain at least one special character'
  );

export const ConfirmPasswordSchema = Yup.string().when('password', (password, field) => {
  if (password) {
    return field
      .required('The passwords do not match')
      .oneOf([Yup.ref('password')], 'The passwords do not match');
  }
  return field.required('This field is required');
});

export const PasswordConfirmPasswordSchema = Yup.object().shape({
  password: PasswordSchema,
  confirmPassword: ConfirmPasswordSchema,
});

export const formSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('First name is required')
    .matches(commonRegex.alphabetsAndSingleSpace, FIELD_INVALID_MESSAGES.firstName),
  lastName: Yup.string()
    .nullable()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Last name is required')
    .matches(commonRegex.alphabetsAndSingleSpace, FIELD_INVALID_MESSAGES.lastName),
  email: Yup.string()
    .nullable()
    .typeError('Invalid email')
    .email('Invalid email')
    .required('Email is required'),
  phone: Yup.string().test('len', 'Phone number must be 10 digits', (val) => {
    return !val?.includes('_');
  }),
  dob: Yup.date()
    .nullable()
    .required('Date of birth is required')
    .test('age-check', 'Personal guarantor must be 18 years of age or older', (value) => {
      if (!value) return false; // handle null or undefined

      // TODO: sumita1602 we should handle date validations in such a way that they do not depend on client side
      const birthDate = new Date(value);
      const today = new Date();

      // Calculate the minimum birth date to be considered 18 years old
      const minimumDate = new Date(
        today.setFullYear(today.getFullYear() - MINIMUM_GUARANTOR_AGE)
      );

      // Check if the provided birthDate is on or before the minimumDate
      return birthDate <= minimumDate;
    }),

  street: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  country: Yup.string(),
  zip: zipSchema,
  title: Yup.string()
    .nullable()
    .required('Job title is required')
    .test('validity check', 'Please enter a valid job title', (value) => {
      if (value) return commonRegex.alphabetsAndSpaces.test(value);
      return false;
    }),
  percentageOfOwnership: Yup.string().required('Percentage of ownership is required'),
});

export const ssnSchema = Yup.object().shape({
  ssn: Yup.string()
    .required('SSN is required')
    .transform((value) => value.replace(commonRegex.remove_underscores, ''))
    .min(SSN_SIN_FIELD_CONFIG.LENGTH, 'Invalid SSN format'),
});
export const sinSchema = Yup.object().shape({
  sin: Yup.string()
    .transform((value) => value.replace(commonRegex.remove_underscores, ''))
    .test(
      'len',
      'Invalid SIN format',
      (value) => !value || value.length === SSN_SIN_FIELD_CONFIG.LENGTH
    ),
});
